.container {
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
    color: #333;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .label {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: #555;
  }
  
  .input,
  .select {
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    width: 100%;
  }
  
  .addressField {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .addButton,
  .removeButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
  }
  
  .removeButton {
    background-color: #dc3545;
  }
  
  .addButton:hover,
  .removeButton:hover {
    opacity: 0.9;
  }
  
  .saveButton,
  .cancelButton {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .cancelButton {
    background-color: #ffc107;
  }
  
  .saveButton:hover,
  .cancelButton:hover {
    opacity: 0.9;
  }
  
  .buttonGroup {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
  