/* Container for the Landing Page */
.landingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%; /* Full height of the content area */
    text-align: center;
    background-color: #f8f9fa;
  }
  
  /* Welcome Message */
  .welcomeMessage {
    font-size: 2rem;
    font-weight: bold;
    color: #343a40;
    margin-bottom: 1.5rem;
  }
  
  /* Instructions Text */
  .instructions {
    font-size: 1rem;
    color: #6c757d;
    margin: 0;
    padding: 0 1rem; /* Add padding for better readability on smaller screens */
  }
  