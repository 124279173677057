/* src/index.css */

/* CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f9;
  color: #333;
  line-height: 1.6;
}

/* Theme colors */
:root {
  --primary-color: #6200ea;
  --secondary-color: #03dac5;
  --background-color: #ffffff;
  --text-color: #333;
}

.container {
  max-width: 900px;
  margin: auto;
  padding: 1rem;
}
