/* Layout Styles */
.mainContent {
  display: flex;
  height: 100vh; /* Ensures full height for sidebar and content */
}

/* Sidebar Styles */
.sidebar {
  width: 250px; /* Adjust sidebar width */
  background-color: #f8f9fa;
  padding: 1rem;
  border-right: 1px solid #ccc;
  overflow-y: auto; /* Allow scrolling if sidebar content overflows */
}

.menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.parentMenu {
  margin-bottom: 1rem;
}

.menuTitle {
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.caret {
  font-size: 1.25rem;
  font-weight: bold;
}

.subMenu {
  list-style: none;
  padding-left: 1rem;
  margin: 0.5rem 0;
}

.menuItem {
  display: block;
  padding: 0.5rem;
  color: #007bff;
  text-decoration: none;
  font-size: 0.875rem;
}

.menuItem:hover {
  color: #0056b3;
}

.activeMenuItem {
  font-weight: bold;
  color: #0056b3;
}

/* Content Area Styles */
.contentArea {
  flex: 1; /* Fills remaining space beside sidebar */
  padding: 1.5rem;
  background-color: #f4f4f4;
  overflow-y: auto; /* Allow scrolling for overflowing content */
}

/* Landing Page Styles */
.landingContainer {
  text-align: center;
  padding: 2rem;
  height: 100%; /* Ensure full height for the landing container */
}

.welcomeMessage {
  font-size: 1.5rem;
  color: #343a40;
  margin-bottom: 1rem;
}

.instructions {
  font-size: 1rem;
  color: #666;
}

/* For small screens */
@media (max-width: 768px) {
  .sidebar {
    width: 200px; /* Reduce sidebar width for smaller screens */
  }

  .contentArea {
    padding: 1rem; /* Reduce padding for smaller screens */
  }
}


/* Header Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #007bff; /* Blue background */
  color: white; /* White text */
  padding: 1rem 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for separation */
}

.title {
  font-size: 1.5rem; /* Adjust font size */
  font-weight: bold; /* Make it stand out */
  margin: 0;
}

.logoutButton {
  background-color: #ff5252; /* Red background for the button */
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logoutButton:hover {
  background-color: #e53935; /* Darker red on hover */
}


.languageSwitcher {
  display: flex;
  gap: 10px;
  margin-right: 20px;
}

.languageButton {
  background: #f0f0f0;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.languageButton:hover {
  background: #e0e0e0;
}

.logoutButton {
  margin-left: auto;
  background: #ff6b6b;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.logoutButton:hover {
  background: #ff5252;
}


/* Header Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #007bff;
  color: white;
  padding: 1rem;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

.headerActions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 1.5rem;
  transition: color 0.2s ease;
}

.iconButton:hover {
  color: #f0ad4e;
}

.languageSelector {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.languageDropdown {
  border: none;
  background: white;
  padding: 0.3rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  cursor: pointer;
}

.languageDropdown:hover {
  background: #f8f9fa;
}

.icon {
  font-size: 1.5rem;
  cursor: pointer;
}
