/* Container for the entire page */
.container {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-family: Arial, sans-serif;
  }
  
  /* Page title styling */
  h2 {
    text-align: center;
    color: #343a40;
    margin-bottom: 1.5rem;
  }
  
  /* Labels for form fields */
  .label {
    display: block;
    font-size: 1rem;
    color: #495057;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  /* Input fields styling */
  .input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    margin-bottom: 1rem;
    font-size: 1rem;
  }
  
  /* Select dropdown styling */
  .select {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    margin-bottom: 1rem;
    font-size: 1rem;
    background-color: #ffffff;
  }
  
  /* Checkbox styling */
  .checkbox {
    margin-right: 0.5rem;
  }
  
  /* Warning message styling */
  .warning {
    color: #d9534f;
    font-size: 0.9rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  /* File input styling */
  .fileInput {
    display: block;
    margin-bottom: 1rem;
    font-size: 0.9rem;
  }
  
  /* Actions container */
  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
  }
  
  /* Save button styling */
  .saveButton {
    background-color: #28a745;
    color: #ffffff;
    border: none;
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .saveButton:hover {
    background-color: #218838;
  }
  
  /* Cancel button styling */
  .cancelButton {
    background-color: #ffc107;
    color: #212529;
    border: none;
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .cancelButton:hover {
    background-color: #e0a800;
  }
  
  /* Table styling for multi-select items */
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
  }
  
  .table th,
  .table td {
    border: 1px solid #dee2e6;
    padding: 0.75rem;
    text-align: left;
  }
  
  .table th {
    background-color: #f8f9fa;
    font-weight: bold;
  }
  
  .table td {
    background-color: #ffffff;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .container {
      padding: 1rem;
    }
  
    .saveButton,
    .cancelButton {
      width: 100%;
      margin-bottom: 0.5rem;
    }
  
    .actions {
      flex-direction: column;
      gap: 0.5rem;
    }
  }
  