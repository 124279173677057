/* src/components/Admin/AdminPage.module.css */

.adminContainer {
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-top: 1rem;
    max-width: 600px;
  }
  
  .userForm {
    display: flex;
    flex-direction: column;
  }
  
  .formControl {
    margin-bottom: 1.5rem;
  }
  
  .formControl label {
    font-weight: 500;
    color: var(--primary-color);
    margin-bottom: 0.5rem;
    display: block;
  }
  
  .inputField {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .inputField:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 5px rgba(98, 0, 234, 0.3);
  }
  
  .readOnly {
    background-color: #f0f0f0;
  }
  
  .submitButton {
    padding: 0.75rem;
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.3s;
    margin-top: 1rem;
  }
  
  .submitButton:hover {
    background-color: #3700b3;
  }
  
  /* User Table */
.userTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .userTable th, .userTable td {
    padding: 0.75rem;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .userTable th {
    background-color: var(--primary-color);
    color: #ffffff;
    font-weight: 600;
  }
  
  .userTable tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .createUserButton {
    padding: 0.75rem;
    background-color: var(--secondary-color);
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 1rem;
    transition: background 0.3s;
  }
  
  .createUserButton:hover {
    background-color: #018786;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logoutButton {
    padding: 0.5rem 1rem;
    background-color: #ff5252;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background 0.3s;
  }
  
  .logoutButton:hover {
    background-color: #ff1744;
  }
  
  .container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 0.9rem;
  }
  
  .input,
  .select,
  .checkbox {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .saveButton {
    width: 100%;
    background-color: #007bff;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .saveButton:hover {
    background-color: #0056b3;
  }
  
  .successMessage {
    text-align: center;
    background-color: #d4edda;
    color: #155724;
    padding: 15px;
    border: 1px solid #c3e6cb;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .actionButton {
    flex: 1;
    margin: 0 5px;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .actionButton:hover {
    background-color: #0056b3;
  }
  /**/
