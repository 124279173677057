/* Container Styles */
.container {
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin: 2rem auto;
  max-width: 1200px;
}
  
  /* Header Section */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .searchBox {
    width: 300px;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .newButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .newButton:hover {
    background-color: #0056b3;
  }
  
  /* Table Styles */
  .organizationTable {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
  }
  
  .organizationTable th,
  .organizationTable td {
    text-align: left;
    padding: 0.75rem;
    border: 1px solid #ddd;
  }
  
  .organizationTable th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .organizationTable tr:hover {
    background-color: #f1f1f1;
  }
  
  /* Status Buttons */
  .activeStatus {
    color: white;
    background-color: #28a745;
    border: none;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .inactiveStatus {
    color: white;
    background-color: #dc3545;
    border: none;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Download Section */
  .downloadSection {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;
  }
  
  .downloadType {
    padding: 0.5rem;
    margin-right: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .downloadButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .downloadButton:hover {
    background-color: #0056b3;
  }
  
  .title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
  }
  
  .actionIcons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .iconButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .iconButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .iconButton:hover:not(:disabled) {
    background-color: #0056b3;
  }
  
  .tableContainer {
    overflow-x: auto;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
  }
  
  .table th,
  .table td {
    border: 1px solid #ddd;
    padding: 0.75rem;
    text-align: left;
  }
  
  .table th {
    background-color: #f4f4f4;
    font-weight: bold;
    color: #333;
  }
  
  .table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .table tr:hover {
    background-color: #f1f1f1;
  }
  
  .table td {
    font-size: 0.9rem;
    color: #555;
  }