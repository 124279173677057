/* Layout Styles */
.layoutContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.mainContent {
  display: flex;
  flex: 1;
  height: 100vh; /* Sidebar and content fill the viewport height */
}

.sidebar {
  width: 250px;
  background-color: #343a40;
  color: white;
  height: 100vh; /* Sidebar spans the full height */
  overflow-y: auto; /* Allow scrolling if needed */
  padding: 1rem;
}

.contentArea {
  flex: 1;
  padding: 1.5rem;
  background-color: #f4f4f4;
  overflow-y: auto;
  height: 100vh; /* Full height to support child elements */
}

/* Menu Styles */
.menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.parentMenu {
  margin-bottom: 1rem;
}

.menuTitle {
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}

.caret {
  font-size: 1.25rem;
  color: #fff;
}

.subMenu {
  list-style: none;
  padding-left: 1rem;
  margin: 0.5rem 0;
}

.menuItem {
  display: block;
  padding: 0.5rem;
  color: #007bff;
  text-decoration: none;
  font-size: 0.875rem;
}

.menuItem:hover {
  color: #0056b3;
}

.activeMenuItem {
  font-weight: bold;
  color: #0056b3;
}

/* Login Container */
.loginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;
}

/* Login Title */
.loginTitle {
  font-size: 1.75rem;
  color: #343a40;
  margin-bottom: 1.5rem;
  text-align: center;
}

/* Error Message */
.error {
  color: red;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

/* Input Fields */
.inputField {
  width: 100%;
  max-width: 400px;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.inputField:focus {
  border-color: #007bff;
  outline: none;
}

/* Login Button */
.loginButton {
  width: 100%;
  max-width: 400px;
  padding: 0.75rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.loginButton:hover {
  background-color: #0056b3;
}

/* Header Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #007bff; /* Blue background */
  color: white; /* White text */
  padding: 1rem 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for separation */
}

.title {
  font-size: 1.5rem; /* Adjust font size */
  font-weight: bold; /* Make it stand out */
  margin: 0;
}

.logoutButton {
  background-color: #ff5252; /* Red background for the button */
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logoutButton:hover {
  background-color: #e53935; /* Darker red on hover */
}
