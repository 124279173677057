/* Container for the entire page */
.container {
  max-width: 1000px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: Arial, sans-serif;
}

/* Page header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

/* Search box styling */
.searchBox {
  width: 50%;
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;
}

/* New button styling */
.newButton {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.newButton:hover {
  background-color: #0056b3;
}

/* Action icons container */
.actionIcons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-bottom: 1rem;
}

/* Icon button styling */
.iconButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.iconButton:disabled {
  background-color: #e9ecef;
  color: #6c757d;
  cursor: not-allowed;
}

.iconButton:hover:not(:disabled) {
  background-color: #0056b3;
}

/* Table styling */
.userTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.userTable th,
.userTable td {
  border: 1px solid #dee2e6;
  padding: 0.75rem;
  text-align: left;
}

.userTable th {
  background-color: #f8f9fa;
  font-weight: bold;
}

.userTable td {
  background-color: #ffffff;
}

/* Checkbox styling in table */
.userTable input[type='checkbox'] {
  cursor: pointer;
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .newButton {
    width: 100%;
  }

  .actionIcons {
    flex-direction: column;
    gap: 0.5rem;
  }

  .iconButton {
    width: 100%;
  }

  .searchBox {
    width: 100%;
  }
}
