.resetContainer {
    max-width: 400px;
    margin: 2rem auto;
    padding: 1.5rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .resetTitle {
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    color: #333;
  }
  
  .inputField {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .inputField:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .error {
    color: red;
    margin-bottom: 1rem;
    font-size: 0.875rem;
  }
  
  .success {
    color: green;
    margin-bottom: 1rem;
    font-size: 0.875rem;
  }
  
  .submitButton {
    width: 100%;
    padding: 0.75rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submitButton:hover {
    background-color: #0056b3;
  }
  
  .backButton {
    width: 100%;
    margin-top: 1rem;
    padding: 0.75rem;
    background-color: #6c757d;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .backButton:hover {
    background-color: #5a6268;
  }
  