.container {
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin: 2rem auto;
    max-width: 1200px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .searchBox {
    padding: 0.5rem;
    font-size: 1rem;
    width: 60%;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .newButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .newButton:hover {
    background-color: #0056b3;
  }
  
  .actionIcons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .iconButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .iconButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .companyTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
  }
  
  .companyTable th,
  .companyTable td {
    border: 1px solid #ddd;
    padding: 0.75rem;
    text-align: left;
  }
  
  .companyTable th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .companyTable tr:hover {
    background-color: #f1f1f1;
  }
  